export const requestWrapper = () => {
    return {
        get: request("GET"),
        post: request("POST"),
        put: request("PUT"),
        delete: request("DELETE"),
    };

}

const request = (method: any) =>{
    return async (url: string,body?: any, options?: any) => {
        const nuxtApp = useNuxtApp();
        //get auth headers
        const authHeaders = useAuthHeaders();
        const {logout} = useAuth();
        //console.log('authHeaders', authHeaders);
        //console.log('url', url);
        //if url contains /api/ then add the apiUrl from nuxt.config to the url replacing /api/
        
        if(url.includes('/api/')) {
            const runtimeConfig = useRuntimeConfig();
            //const apiProxyUrl = runtimeConfig.public.apiUrl;
            url = runtimeConfig.public.apiUrl + url
            //if its dev replace /api/ with /
            if(runtimeConfig.public.apiUrl.includes('localhost')) {
                url = url.replace('/api/', '/');
            }
            //console.log('new url', url);
        }
        else if(url.includes('/cut')) {
            const runtimeConfig = useRuntimeConfig();
            //const apiProxyUrl = runtimeConfig.public.apiUrl;
            url = runtimeConfig.public.uploadUrl + '/cut';
            //console.log('new url', url);
        }
        console.log('url', url);
       /*
        const runtimeConfig = useRuntimeConfig();
        url = runtimeConfig.public.apiUrl + url;
        */
        const { data, status, error, refresh, execute } = await useFetch(url,
            {
                method: method,
                headers: {...authHeaders, ...options?.headers},
                body: body instanceof FormData ? body : JSON.stringify(body),
                ...options
            }
        );
        if(error.value) {
            console.log('error', error.value);
        }
        //console.log(JSON.stringify(data))
        //if body is formdata, do not stringify
        //if (body instanceof FormData) {
            
        if(error.value?.statusCode === 401) {
            console.log('logout');
            nuxtApp.runWithContext(() => logout());
        }
        if(status.value === 'error') {
            useToast().error(data.value?.message);
        }

        return { data, status, error, refresh, execute};
    }
}