// useAuth.ts
export function useAuthHeaders() {
  const { getToken } = useTokenStore();
  const authHeaders = {
    'Authorization': `Bearer ${getToken()}`,
  };
  return authHeaders;
}
export function useAuth() {
  const nuxtApp = useNuxtApp();
  const username = ref('');
  const password = ref('');
  const { setToken, getToken } = useTokenStore();
  const login = async() => {
    //use requestWrapper.post to send the username and password to the backend
    const { data, status, error, refresh } = await requestWrapper().post(
      '/api/admin/login',
      {
        username: username.value,
        password: password.value,
      }
    );
    
    if (status.value === 'success') {
      
      setToken(data.value?.token);
      //nuxt log terminal
      //sleep for 1 second
      //await new Promise((resolve) => setTimeout(resolve, 100));
      return nuxtApp.runWithContext(() => navigateTo('/admin'));
    }
    return { data, status, error, refresh };
  };

  const logout = async() => {

    setToken('');
    //useRouter().push('/login');
    return nuxtApp.runWithContext(() => navigateTo('/login'));
    //await navigateTo('/login');
    
  };

  const isAuthenticated = async() => {
    const {data, status, error, refresh} = await requestWrapper().get('/api/admin/check');
  
    return status.value === 'success';
  };

  return {
    username,
    password,
    login,
    logout,
    isAuthenticated,
  };
}
